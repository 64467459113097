.menu-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5rem;
}

.mobileNavbar {
  width: 70%;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgb(23, 23, 23);
  border-left: 1px solid var(--red);
  padding: 5rem;
  padding-bottom: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.nav-menu-mobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 5rem;
}

.nav-link.special {
  color: rgb(175, 37, 26);
}

.languageBtnMobile {
  width: 100%;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
