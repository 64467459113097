.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem;
}

@media (max-width: 768px) {
  .footer {
    padding: 4rem 2rem;
  }

  .footer p {
    font-size: 1rem;
  }
}
