.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal-content {
  width: fit-content;
  min-width: 50vw;
  height: 80vh;
  background: rgb(0, 0, 0);
  padding: 2rem;
  border-radius: 8px;
  max-width: 80vw;
  position: relative;
  box-shadow: 0px 0px 20px rgba(126, 242, 231, 0.08);
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: var(--orange);
}

.carousel-container {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalImage {
  width: 100%;
  height: 65vh;
  object-fit: fill;
  border-radius: 8px;
}

.modalLogo {
  width: 10rem;
  height: auto;
  align-self: center;
}

@media (max-width: 768px) {
  .modal-content {
    height: 90vh;
    width: 90vw;
    max-width: none;
  }
  .modalTitle {
    font-size: 3rem;
  }
  .modalImage {
    width: 100%;
    height: 100%;
    max-height: 80vh;
    object-fit: fill;
    padding: 1rem 0;
  }
  .modalLogo {
    width: 5rem;
  }
}

