.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem;
}

.contactContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.contactInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  row-gap: 2rem;
}

.listElement {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.listElement a {
  font-family: Plus Jakarta Sans;
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 48px;
  text-align: center;
  color: var(--white);
  margin: 0;
}

.contactForm {
  position: relative;
  width: 55%;
  min-height: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #151515;
  padding: 5rem;
  row-gap: 1.5rem;
  border-radius: 0.5rem;
}

.inputContainer {
  width: 100%;
  height: fit-content;
  position: relative;
  margin-bottom: 2rem;
}

label {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: 0.3s ease;
  color: #646464;
  pointer-events: none;
  font-size: 1.8rem;
}

input,
textarea {
  width: 100%;
  border: none;
  border-bottom: 2px solid #646464;
  padding: 10px 0;
  font-size: 2rem;
  outline: none;
  background: none;
  transition: border-color 0.3s ease, padding 0.3s ease;
  color: white;
}

input:focus,
textarea:focus {
  border-bottom-color: #646464;
  padding-bottom: 5px;
}

input:focus + label,
input:not(:placeholder-shown) + label,
textarea:focus + label,
textarea:not(:placeholder-shown) + label {
  top: 0px;
  font-size: 1.2rem;
  color: white;
}

textarea {
  height: 50px;
  resize: none;
}

@media (max-width: 768px) {
  .contact {
    padding: 4rem 2rem;
  }
  .contactContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
  }
  .contactInfo {
    width: 100%;
    padding: 3rem 0;
    align-items: center;
  }
  .listElement {
    align-items: center;
    gap: 1rem;
  }

  .contactForm {
    width: 100%;
    padding: 3rem;
  }
}

.loaderContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
