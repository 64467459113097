.nav-logo {
  width: 7rem;
  height: auto;
}

.languageBtn {
  width: 2rem;
  background-color: transparent;
  border: none;
}

.languageBtn:hover{
  opacity: 0.5;
  transition: all 0.3s ease;

}
@media (max-width: 768px) {
  .nav-logo {
    width: 6rem;
  }
  .nav-menu {
    display: none;
  }
}

.hamburgerMenu {
  width: 5rem;
  height: 5rem;
  background-color: var(--red);
  border-radius: 0.5rem;
}


