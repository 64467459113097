.top-bg {
  width: 100%;
  height: fit-content;
  /* add transparency */
  background: rgba(0, 0, 0, 0.6);
  background-image: url("./images/header.webp");
  background-size: cover;
  background-repeat: no-repeat;
}

.top-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(13, 13, 13, 0) 50%,
    rgba(13, 13, 13, 0.5) 60%,
    rgba(13, 13, 13, 1) 80%,
    rgba(13, 13, 13, 0.5) 100%
  );
  z-index: 1;
}

.top-bg > * {
  position: relative;
  z-index: 2;
}

.mid-bg {
  position: relative;
  width: 100%;
  height: fit-content;
  background-image: url("./images/skills.webp");
  background-size: cover;
  background-position: center;
}

.mid-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(13, 13, 13, 0) 10%,
    rgba(13, 13, 13, 0.6) 50%,
    rgba(13, 13, 13, 1) 100%
  );
  z-index: 1;
}

.mid-bg > * {
  position: relative;
  z-index: 2;
}

.bottom-bg {
  width: 100%;
  height: fit-content;
  position: relative;
  /* add transparency */
  background: rgba(0, 0, 0, 0.8);
  background-image: url("./images/header.webp");
  background-size: cover;
  background-repeat: no-repeat;
}

.bottom-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgb(13, 13, 13, 1) 0%,
    rgba(13, 13, 13, 0.5) 15%,
    rgba(13, 13, 13, 0) 30%
  );
  z-index: 1;
}

.bottom-bg > * {
  position: relative;
  z-index: 2;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: var(--red);
  transform-origin: 0%;
  z-index: 10;
}