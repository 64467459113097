.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem;
  padding-top: 10rem;
}

.grid {
  width: 100%;
  padding: 5rem 0;
  display: grid;
  grid-template-areas:
    "a b"
    "c d"
    "e f"
    "g h";
  column-gap: 2rem;
  row-gap: 2rem;
}

.projectBox {
  background-color: #151515;
  height: 35rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
}

.projectBox:nth-of-type(1) {
  grid-area: a;
  grid-column: span 2; /* 2fr 1fr for the first row */
}

.projectBox:nth-of-type(2) {
  grid-area: b;
  grid-column: span 2;
}

.projectBox:nth-of-type(3) {
  grid-area: c;
  grid-column: span 3; /* 1fr 1fr for the second row */
}

.projectBox:nth-of-type(4) {
  grid-area: d;
  grid-column: span 1;
}

.projectBox:nth-of-type(5) {
  grid-area: e;
  grid-column: span 1; /* 1fr 2fr for the third row */
}

.projectBox:nth-of-type(6) {
  grid-area: f;
  grid-column: span 3;
}

.projectBox:nth-of-type(7) {
  grid-area: g;
  grid-column: span 2; /* 2fr 1fr for the fourth row */
}

.projectBox:nth-of-type(8) {
  grid-area: h;
  grid-column: span 2;
}

.image-container {
  width: 23rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.projectLogo {
  width: 100%;
  height: 100%;
  object-fit:contain;
}

.modalButton {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--orange);
}

@media (max-width: 768px) {
  .projects {
    padding: 4rem 2rem;
  }
  .grid {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}
