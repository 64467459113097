.header {
  height: 90rem;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 5rem 10rem;
  padding-top: 0;
}

.linkedInPic {
  position: absolute;
  top: 0;
  height: auto;
  width: 50rem;
  z-index: 0;
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  -webkit-mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) 100%
  );
}

.header h1 {
  z-index: 1;
  margin-bottom: 2rem;
}

.header p {
  z-index: 1;
  margin-bottom: 4rem;
  text-wrap: wrap;
  padding: 0 2rem;
}

@media (max-width: 768px) {
  .header {
    height: 65rem;
    padding: 4rem 2rem;
  }

  .linkedInPic {
    padding-top: 0;
    width: 30rem;
  }
}
