.skills {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem;
  padding-top: 10rem;
}

.skillsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5rem;
  margin: 5rem 0;
}

.skillBox {
  width: 40rem;
  height: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  background-color: #0000001c;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(3px);
  padding: 3rem;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 20px rgba(126, 242, 231, 0.08);
}

.skillBox:nth-of-type(2){
  justify-content: center;
  align-items: center;
  gap: 5rem;
}

.grid2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: 3rem;
}
.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 3rem;
}
.grid4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  column-gap: 1rem;
  row-gap: 3rem;
}

.languageItem {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.polygonBig {
  width: 14rem;
  height: 14rem;
}

.polygon {
  width: 6.4rem;
  height: 6.4rem;
}

.languageImgBig {
  position: absolute;
  top: 25%;
  width: 5rem;
  height: 5rem;
  margin: 0 auto;
}

.languageImg {
  position: absolute;
  top: 20%;
  height: 2.7rem;
  width: auto;
  margin: 0 auto;
}

.label {
  font-family: Plus Jakarta Sans;
  font-size: 15px;
  font-weight: 800;
  line-height: 22px;
  text-align: center;
  text-wrap: nowrap;
}

@media (max-width: 768px) {
  .skills {
    padding: 4rem 2rem;
  }
  .skillsContainer {
    flex-direction: column;
  }
  .skillBox {
    flex-direction: column-reverse;
    justify-content: center;
    gap: 5rem;
  }
}
