/* Variables */
:root {
  --red: #bf0404;
  --blue: #7ef2e7;
  --white: #fff;
  --black: #0d0d0d;
  --orange: #d95a10;
}

/* Fonts */

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../public/fonts/PlusJakartaSans-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../public/fonts/PlusJakartaSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../public/fonts/PlusJakartaSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}



html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, "Plus Jakarta Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--black);
  overscroll-behavior: none;
}

#root {
  height: 100%;
}

code {
  font-family: -apple-system, "Plus Jakarta Sans", sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Typography */

h1 {
  font-family: Plus Jakarta Sans;
  font-size: 10rem;
  font-weight: 800;
  text-align: center;
  color: var(--white);
  margin: 0;
}

h2 {
  font-family: Plus Jakarta Sans;
  font-size: 6.5rem;
  font-weight: 700;
  text-align: center;
  color: var(--white);
}

h3 {
  font-family: Plus Jakarta Sans;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 2.2rem;
  text-transform: uppercase;
  letter-spacing: 0.65em;
  text-align: center;
  color: var(--blue);
}

span {
  color: var(--blue);
}

p {
  font-family: Plus Jakarta Sans;
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 48px;
  text-align: center;
  color: var(--white);
  margin: 0;
}

.nav-link {
  font-family: Plus Jakarta Sans;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.2rem;
  text-align: center;
  color: var(--white);
  text-decoration: none;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

/* Buttons */

button {
  border: none;
  cursor: pointer;
}

.btn {
  font-family: Plus Jakarta Sans;
  font-size: 2rem;
  font-weight: 800;
  line-height: 22px;
  text-align: center;
  color: var(--white);
  background-color: var(--red);
  padding: 1.5rem 3rem;
}

.smallBtn {
  font-size: 1.6rem;
  padding: 1rem 2rem;
}

/* Layout */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 6rem;
  padding-bottom: 0;
}

nav ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  gap: 2rem;
  position: relative;

}

a {
  text-decoration: none;
  color: var(--white);
}

.maxWidth {
  max-width: 120rem;
  margin: 0 auto;
}

.titleContainer {
  padding-bottom: 5rem;
}

/* Media Queries */

@media (max-width: 768px) {
  html {
    overflow-x: hidden;
  }

  body {
    overflow-x: hidden;
  }

  h1 {
    font-size: 4.2rem;
  }
  h2 {
    font-size: 3.2rem;
  }
  h3 {
    font-size: 2rem;
    letter-spacing: 0.25em;
  }
  p {
    font-size: 2rem;
    line-height: 30px;
  }

  .nav-link {
    font-size: 2rem;
  }

  .navbar {
    padding: 3rem 4.5rem;
  }


  .btn {
    width: 100%;
    font-size: 2.5rem;
  }

  .titleContainer {
    padding-bottom: 1rem;
  }
}
