.education {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10rem 5rem 15rem 5rem;
}

.educationContainer {
  display: grid;
  grid-template-areas:
    "a b"
    "c d";
  column-gap: 10rem;
  row-gap: 10rem;
}

.boxTitle:nth-of-type(1) {
  grid-area: a;
}

.boxTitle:nth-of-type(2) {
  grid-area: b;
}

.boxContainer:nth-of-type(3) {
  grid-area: c;
}

.boxContainer:nth-of-type(4) {
  grid-area: d;
}

.boxTitle {
  font-family: Plus Jakarta Sans;
  font-size: 2rem;
  font-weight: 800;
  line-height: 2.2rem;
  text-transform: uppercase;
  letter-spacing: 0.65em;
  text-align: left;
  color: var(--blue);
}

.boxContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: 2rem;
}

.certificateBox {
  width: 25rem;
  height: 25rem;
  padding: 4rem;
  background-color: #151515;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: 0.5rem;
}

.educationBox {
  width: 100%;
  height: fit-content;
  padding: 4rem;
  background-color: #151515;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  border-radius: 0.5rem;
}

.polygonContainer {
  position: relative;
}

.polygon {
  width: 6.4rem;
  height: 6.4rem;
}

.icon {
  position: absolute;
  top: 30%;
  left: 30%;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 auto;
}

.text {
  font-family: Plus Jakarta Sans;
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  line-height: 1.2;
  color: var(--white);
}

.career {
  font-size: 2.5rem;
  color: var(--white);
  text-align: left;
  line-height: 2.5rem;
}

.school {
  font-size: 1.5rem;
  color: var(--orange);
  font-style: italic;
  text-align: left;
  line-height: 1rem;
}

.year {
  font-size: 1.5rem;
  color: #606060;
  text-align: left;
  line-height: 1rem;
  letter-spacing: 0.2em;
}

@media (max-width: 768px) {
  .education {
    padding: 4rem 2rem;
  }
  .educationContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 4.5rem;
    margin-top: 4rem;
  }
  .boxContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
  }
  .educationBox {
    align-items: center;
  }
  .certificateBox {
    width: 100%;
    height: 25rem;
  }
  .educationBox .polygon {
    width: 10rem;
    height: 10rem;
  }
  .certificateBox .polygon {
    width: 10rem;
    height: 10rem;
  }
  .icon {
    top: 28%;
    left: 29%;
    width: 4rem;
    height: 4rem;
  }
  .text {
    font-size: 2rem;
  }
  .career {
    text-align: center;
  }
}
